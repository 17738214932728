import {trackSignUpClick} from 'utils/analytics';

import {useLatestAgentVersion} from '@polarsignals/hooks/';

const Docker = () => {
  const agentVersion = useLatestAgentVersion();

  return (
    <>
      <div className="flex h-auto w-auto flex-col items-start gap-y-3 rounded-md bg-[#272822] py-4 px-5 lg:min-w-[800px]">
        <div className="flex gap-3">
          <div className="h-3 w-3 rounded-full border border-white/20 bg-red-500" />
          <div className="h-3 w-3 rounded-full border border-white/20 bg-yellow-500" />
          <div className="h-3 w-3 rounded-full border border-white/20 bg-green-500" />
        </div>

        <code className="my-3 text-left text-[8px] text-white lg:text-sm">
          docker run --rm -it \<br />
          &nbsp;&nbsp;-p 7071:7071 \<br />
          &nbsp;&nbsp;--privileged \<br />
          &nbsp;&nbsp;--pid host \<br />
          &nbsp;&nbsp;-v /sys/fs/bpf:/sys/fs/bpf \<br />
          &nbsp;&nbsp;-v /run:/run \<br />
          &nbsp;&nbsp;-v /boot:/boot \<br />
          &nbsp;&nbsp;-v /lib/modules:/lib/modules \<br />
          &nbsp;&nbsp;-v /sys/kernel/debug:/sys/kernel/debug \<br />
          &nbsp;&nbsp;-v /sys/fs/cgroup:/sys/fs/cgroup \<br />
          &nbsp;&nbsp;-v /var/run/dbus/system_bus_socket:/var/run/dbus/system_bus_socket \<br />
          &nbsp;&nbsp;ghcr.io/parca-dev/parca-agent:{agentVersion} \<br />
          &nbsp;&nbsp;/bin/parca-agent \<br />
          &nbsp;&nbsp;--remote-store-address=grpc.polarsignals.com:443 \<br />
          &nbsp;&nbsp;--remote-store-bearer-token=
          <a
            className="italic underline"
            href="https://cloud.polarsignals.com/getting-started"
            onClick={() => trackSignUpClick('your-token-here-link')}
          >
            &#60;your-token-here&#62;
          </a>
        </code>
      </div>

      <p className="!mb-0 mt-2 text-center text-xs italic">
        If you want to understand what you're applying here and what each flag does,{' '}
        <a
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.polarsignals.com/docs/setup-collection-docker"
        >
          check out the docs
        </a>
        .
      </p>
    </>
  );
};

export default Docker;
