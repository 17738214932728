import React from 'react';
import {Tab} from '@headlessui/react';
import {Icon} from '@iconify/react';
import cx from 'classnames';

import Kubernetes from './Kubernetes';
import Docker from './Docker';
import {trackSignUpClick} from 'utils/analytics';

const collectionMethods = [
  {
    name: 'Kubernetes',
    component: <Kubernetes />,
    icon: 'cib:kubernetes',
  },
  {
    name: 'Docker',
    component: <Docker />,
    icon: 'cib:docker',
  },
];

const GetStartedSection = () => {
  return (
    <section className="bg-[#F2F2F2]">
      <div className="container mx-auto flex w-full justify-center gap-0 py-12 px-4 md:px-8 lg:flex-row lg:gap-10 lg:px-12 lg:py-24">
        <div className="flex flex-col items-center gap-7 text-center lg:w-full lg:text-left">
          <p className="lg:text-4.5xl text-3xl font-semibold text-gray-900">
            Get started in seconds
          </p>

          <p className="!mb-0 text-center font-sans text-base font-normal text-gray-800 lg:max-w-[422px]">
            With just one command and the easiest onboarding guide you’ll ever see, you can start
            saving costs and optimizing performance in your infrastructure.
          </p>

          <div>
            <p className="!mb-0 font-sans text-base font-normal text-gray-800">
              All it takes is a single command.
            </p>
          </div>

          <Tab.Group>
            <Tab.List className="flex gap-2">
              {collectionMethods.map((method, index) => (
                <Tab
                  key={index}
                  className={({selected}) =>
                    cx(
                      selected ? 'border-gray-700 bg-gray-900 text-white' : 'border-gray-500',
                      'flex items-center justify-center rounded border px-4 py-3 text-sm outline-none'
                    )
                  }
                >
                  <span>{method.name}</span>
                  <Icon icon={method.icon} className="ml-2 h-5 w-5" />
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {collectionMethods.map((method, index) => (
                <Tab.Panel key={index}>{method.component}</Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          <a
            href="https://cloud.polarsignals.com"
            className="rounded bg-gray-800 px-10 py-3 font-sans text-base font-medium text-white first-line:text-center hover:opacity-90 lg:py-4 lg:text-lg"
            onClick={() => trackSignUpClick('get-started-in-seconds-cta')}
          >
            Start profiling
          </a>
        </div>
      </div>
    </section>
  );
};

export default GetStartedSection;
